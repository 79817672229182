<template>
    <div class="mb-16">
        <DidntReturnStaffTableSubHeader :subHeaderData="subHeaderData"></DidntReturnStaffTableSubHeader>
        <v-data-table
            :sort-by.sync="sortColumn"
            :sort-desc.sync="sortDesc"
            :headers="headers"
            :items="chartData"
            @current-items="setSubHeaderData"
        >
            <template v-slot:[`item.client_name_with_num`]="{ item }">
                <div>
                    <span class="communication-user">{{ item.client_name_with_num }}</span>
                </div>
                <div>
                    <PhoneLink :hrefInfo="item.href_info"></PhoneLink>
                </div>
            </template>
            <template v-slot:[`item.visit_date`]="{ item }">
                <span>{{ item.visit_date ? moment(item.visit_date).format(dateFormat) : '' }}</span>
            </template>
            <template v-slot:[`item.first_visit`]="{ item }">
                <span>{{ item.first_visit ? moment(item.first_visit).format(dateFormat) : '' }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <div class="table-buttons-wrapper">
                    <AmoCrmLink :hrefInfo="item.href_info"></AmoCrmLink>
                    <YClientsLink :hrefInfo="item.href_info"></YClientsLink>
                    <ProfSalonLink :hrefInfo="item.href_info"></ProfSalonLink>
                    <SonlineLink :hrefInfo="item.href_info"></SonlineLink>
                    <AltegioLink :hrefInfo="item.href_info"></AltegioLink>
                    <DikidiLink :hrefInfo="item.href_info"></DikidiLink>
                    <ChatLink
                        :hrefInfo="item.href_info"
                        :chatLinkSettings="connector.whatsappLinkSettings"
                        :textMessage="getChatTextMessage(item)"
                    ></ChatLink>
                </div>
            </template>
        </v-data-table
        >
    </div>
</template>

<script>
import { openInNewTab } from '../../../../utils'
import { getYcClientRef, toPercent } from '../helpers'
import DidntReturnStaffTableSubHeader from './subHeaders/DidntReturnStaffTableSubHeader.vue'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
import PhoneLink from '@root/src/components/iconLinks/PhoneLink.vue'
import messageVarsService from '@root/src/helpers/gcb2/messageVars'
import AmoCrmLink from '@root/src/components/iconLinks/AmoCrmLink.vue'
import YClientsLink from '@root/src/components/iconLinks/YClientsLink.vue'
import ProfSalonLink from '@root/src/components/iconLinks/ProfSalonLink.vue'
import SonlineLink from '@root/src/components/iconLinks/SonlineLink.vue'
import AltegioLink from '@root/src/components/iconLinks/AltegioLink.vue'
import DikidiLink from '@root/src/components/iconLinks/DikidiLink.vue'
import ChatLink from '@root/src/components/iconLinks/ChatLink.vue'

export default {
    name: 'DidntReturnStaffTable',
    components: { DidntReturnStaffTableSubHeader, AmoCrmLink, YClientsLink, ProfSalonLink, SonlineLink, AltegioLink, DikidiLink, ChatLink, PhoneLink },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
        dateBorders: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        sortColumn: 'visit_date',
        dateFormat: 'DD.MM.YYYY',
        sortDesc: true,
        headers: [
            {
                text: 'Мастер',
                align: 'start',
                value: 'staff_name',
            },
            {
                text: 'Клиент',
                align: 'start',
                value: 'client_name_with_num',
                width: '16%',
            },
            {
                text: 'Услуга',
                align: 'start',
                value: 'service_name',
            },
            {
                text: 'Дата последнего посещения',
                align: 'start',
                value: 'visit_date',
            },
            {
                text: 'Всего посещений у клиента',
                align: 'start',
                value: 'count_attendance',
            },
            {
                text: 'Дата первого посещения',
                align: 'start',
                value: 'first_visit',
            },
            {
                text: 'Филиал первого посещения',
                align: 'start',
                value: 'first_visit_project',
            },
            {
                text: '',
                value: 'action',
                sortable: false,
            },
        ],
        fullBarWidth: 200,
        subHeaderData: {},
    }),
    computed: {
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector() {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
    },
    methods: {
        openInNewTab,
        getYcClientRef,
        setSubHeaderData(curItems) {
            if (curItems.length > 0) {
                this.subHeaderData = {
                    startDate: this.dateBorders.from,
                    endDate: this.dateBorders.to,
                    clientName: curItems[0].client_name_with_num,
                    staffName: curItems[0].staff_name,
                    attCount: curItems[0].count_attendance,
                    lastDate: curItems[0].visit_date,
                    serviceTittle: curItems[0].service_tittle,
                }
            }
        },
        getChatTextMessage(manualCommunication) {
            return messageVarsService.resolveVars(
                manualCommunication.textMessage,
                this.connector,
                manualCommunication
            )
        },
    },

    created() {},
}
</script>
<style lang="sass" scoped>
.bar-wrapper
    display: flex
    flex-direction: row
    align-items: center
.bar
    height: 20px
    background-color: #378EFF
.v-application
    .v-menu__content
        overflow: visible !important
        contain: none !important
.table-buttons-wrapper
    position: relative
    display: flex
    flex-direction: row
    justify-content: flex-end
.communication-user
    font-weight: bold
::v-deep .v-data-table__wrapper
    overflow: visible !important
</style>
